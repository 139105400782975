import './App.css';
import Activity from './components/activity';

function Volumne() {
  return (
	<div >
		<Activity />
    </div>
  );
}

export default Volumne;