import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Activity from './components/activity';
import VolumnePage from './Volumne';
import WalletPage from './Wallet';

function App() {
  return (
    <Router>
      <div className="App">
        <div style={{ width: "75%", margin: "auto" }}>
          <Routes>
            <Route path="/" element={<VolumnePage />} />
            <Route path="/portfolio" element={<WalletPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;