import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './activity.css';
import FlipMove from 'react-flip-move';
import { Sparklines, SparklinesLine } from 'react-sparklines';

const TradeList = ({sortedTradeActivity}) => {
	const formatNum = (num) => {
		return `${parseFloat(num).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;
	};
	const toggleDetails = (index) => {
		/*
		console.log("toggle")
		if (isExpanded === null) {
			setIsExpanded(index);
		} else {
			setIsExpanded(null);
		}*/
	};
	function calculatePercentages(A, B) {
		let total = A + B;
		
		let percentageA = (A / total) * 100;
		let percentageB = (B / total) * 100;
		
		return {
			percentageA: percentageA,
			percentageB: percentageB
		};
	}
	return (
	<FlipMove enterAnimation="fade" leaveAnimation="fade" staggerDurationBy={50} className="flip-move" >
	  {sortedTradeActivity.map((trade, i) => {
		let posVol = parseFloat(trade["BUY-Yes"]) + parseFloat(trade["SELL-No"])
		let negVol = parseFloat(trade["BUY-No"]) + parseFloat(trade["SELL-Yes"])
		let netActivity = posVol - negVol
		// background: netActivity > 0 ? "green" : "red",
		return (
				<div className={"list-item"} id={"index_" + i} key={trade.title} style={{display: "flex", flexDirection: "column",position: 'relative', zIndex: 100}}>
					<div style={{display: 'flex', justifyContent: 'space-between'}} onClick={() => toggleDetails(i)}>
						<a href={'https://polymarket.com/event/' + trade.eventSlug} target='_blank'>
							<div style={{textAlign: 'left'}}>{trade.title}</div>
						</a>
						<div className='sparklines-wrapper' style={{width: 100, position: "relative", marginLeft: "auto"}}>
							<Sparklines style={{background: "#e9e9e9"}} data={trade["yes-prices"].map(e => parseInt(e * 100))} min={0} max={100} margin={0} >
								<SparklinesLine color="blue" />
							</Sparklines>
							{/* trade["yes-prices"].map(e => parseInt(e * 100)) */}
							<div style={{position: 'absolute', display: "none"}}>
							<Sparklines data={trade["no-prices"].map(e => parseInt(e * 100))} width={100} min={0} max={100} height={40} margin={0} >
								<SparklinesLine color="red" />
							</Sparklines>
							</div>
							{/* trade["no-prices"].map(e => e * 100) */}
						</div>
						<div style={{marginLeft: "15px"}}>{(trade.sumPrice / trade.numOfTrades).toFixed(2) + "$"}</div>
						<div style={{marginLeft: "auto", position: 'relative', marginRight: 5, marginLeft: 15, color: "#fff", minWidth: 110}}>
							<div style={{zIndex: 100, position: "relative"}}>{formatNum(netActivity) + "$"}</div>
							<div style={{width: "100%", position: "absolute", top: -4, left: 0, height: "100%", display: "flex"}}>
								<div className='posVol' style={{height: "100%", width: (calculatePercentages(posVol, negVol).percentageA / 100) * 110, background: "#4BB543"}}></div>
								<div className='negVol' style={{height: "100%", mariginLeft: "auto", width: (calculatePercentages(posVol, negVol).percentageB / 100) * 110, background: "#FC100D"}}></div>
							</div>
						</div>
						<div style={{minWidth: 110, textAlign: "right"}}>{formatNum(trade.volumne) + "$"}</div>
						<a style={{textDecoration: "none", color: "#000", width: 65}} target='_blank' href={'https://polymarket.com/event/' + trade.eventSlug}>
							<div className='timeframe-selector' style={{padding: "2px 4px", marginLeft: 15}}>Open</div>
						</a>
					</div>
					
				</div >
				
		)
	  })}
	</FlipMove>
)};
/*
{isExpanded === i && (
						<div className="details" style={{display: 'flex', justifyContent: 'space-between'}}>
							<div>Buy NO {parseFloat(trade["BUY-No"]).toFixed(2) + "$"}</div>
							<div>Sell NO {parseFloat(trade["SELL-No"]).toFixed(2) + "$"}</div>
							<div>Buy YES {parseFloat(trade["BUY-Yes"]).toFixed(2) + "$"}</div>
							<div>Sell YES {parseFloat(trade["SELL-Yes"]).toFixed(2) + "$"}</div>
						</div>
					)}
*/
const Activity = () => {
	const [data, setData] = useState([]);
	const [timeCutOff, setTimeCutOff] = useState(3600000);

	const [error, setError] = useState(null);
	const [volFilter, setVolFilter] = useState(null);
	const [isExpanded, setIsExpanded] = useState(false);

	// Function to toggle the visibility of the details
	
	
	const applyVolFilter = (trade, volFilter) => {
		let toAdd = 0
		if (volFilter === null) {
			toAdd += trade.price * trade.size
		} 
		if (volFilter === "pos") {
			if ((trade.outcome === "Yes" && trade.side === "BUY") || (trade.outcome === "No" && trade.side === "SELL")) {
				toAdd += trade.price * trade.size
			}
		}
		if (volFilter === "neg") {
			if ((trade.outcome === "No" && trade.side === "BUY") || (trade.outcome === "Yes" && trade.side === "SELL")) {
				toAdd += trade.price * trade.size
			}
		}
		return toAdd;
	};
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get('https://polymarket.niklasoe.uber.space/data');
				console.log(response.data)
				setData(response.data);
			} catch (err) {
				setError(err);
			}
		};

		// Fetch data initially
		fetchData();

		// Set up interval to fetch data every minute (60000 milliseconds)
		const intervalId = setInterval(fetchData, 15000);

		// Clean up the interval on component unmount
		return () => clearInterval(intervalId);
	}, []);

	if (error) {
		return <div>Error: {error.message}</div>;
	}

	if (data === null) {
		return <div>Loading...</div>;
	}
  
	let tradeActivity = [];
	for (let i = 0; i < data.length; i++) {
		const trade = data[i];
		const timeFilter = Date.now() - timeCutOff; // 1 hour in milliseconds
		const tradeTimestamp = new Date(trade.timestamp).getTime() * 1000;
	  
		if (tradeTimestamp < timeFilter) {
		  continue;
		}
	  
		// Find the trade activity by slug
		let existingActivity = tradeActivity.find(activity => activity.slug === trade.slug);
	  
		if (!existingActivity) {
		  // If it doesn't exist, create a new activity
		  existingActivity = {
			slug: trade.slug,
			volumne: 0,
			"BUY-No": 0,
			"SELL-No": 0,
			"BUY-Yes": 0,
			"SELL-Yes": 0,
			"yes-prices": [],
			"no-prices": [],
			"numOfTrades": 1,

			...trade
		  };
		  existingActivity["sumPrice"] = trade.outcome === "No" ? 1 - trade.price : trade.price
		  existingActivity["volumne"] += applyVolFilter(trade, volFilter)

		  existingActivity[trade.side + "-" + trade.outcome] = trade.price * trade.size;
		  try {
			//existingActivity[trade.side + "-" + trade.outcome + "-" + "prices"] = [trade.price]
			if ((trade.outcome === "Yes" && trade.side === "BUY") || (trade.outcome === "Yes" && trade.side === "SELL")) {
				existingActivity["yes-prices"].push(trade.price)
			}
			if ((trade.outcome === "No" && trade.side === "BUY") || (trade.outcome === "No" && trade.side === "SELL")) {
				existingActivity["no-prices"].push(trade.price)
			}
		  } catch (error) {
			
		  }
		  tradeActivity.push(existingActivity);
		} else {
		  // If it exists, update the existing activity
		  existingActivity["numOfTrades"] += 1
		  existingActivity["sumPrice"] += trade.outcome === "No" ? 1 - trade.price : trade.price
		  existingActivity["volumne"] += applyVolFilter(trade, volFilter)
		  existingActivity[trade.side + "-" + trade.outcome] += trade.price * trade.size;
		  try {
			if ((trade.outcome === "Yes" && trade.side === "BUY") || (trade.outcome === "No" && trade.side === "SELL")) {
				existingActivity["yes-prices"].push(trade.price)
			}
			if ((trade.outcome === "No" && trade.side === "BUY") || (trade.outcome === "Yes" && trade.side === "SELL")) {
				existingActivity["no-prices"].push(trade.price)
			}
		  } catch (error) {
			
		  }
		}
	  }
	  const sortTradeActivityByVolumne = (activityArray) => {
		return activityArray.sort((a, b) => b.volumne - a.volumne);
	  };
	  
	  // Example usage
	  const sortedTradeActivity = sortTradeActivityByVolumne(tradeActivity);
	  
	  console.log(sortedTradeActivity);

	  
  return (
    <div>
      <h1>Recent Activity</h1>
	  <div style={{display: "flex", justifyContent: "space-between", marginBottom: 15}}>
		<div style={{display: "flex"}}>
			<div style={{lineHeight: "32px", marginRight: 5}}>Filter: </div>
			<div className={'timeframe-selector ' + (volFilter === null ? "active" : "")} onClick={() => setVolFilter(null)}>Both</div>
			<div className={'timeframe-selector ' + (volFilter === "pos" ? "active" : "")} onClick={() => setVolFilter("pos")}>Pos. Vol</div>
			<div className={'timeframe-selector ' + (volFilter === "neg" ? "active" : "")} onClick={() => setVolFilter("neg")}>Neg. Vol</div>
		</div>
		<div style={{display: "flex"}}>
			<div style={{lineHeight: "32px", marginRight: 5}}>time frame: </div>
			<div className={'timeframe-selector ' + (timeCutOff === 300000 ? "active" : "")} onClick={() => setTimeCutOff(300000)}>5min</div>
			<div className={'timeframe-selector ' + (timeCutOff === 900000 ? "active" : "")} onClick={() => setTimeCutOff(900000)}>15min</div>
			<div className={'timeframe-selector ' + (timeCutOff === 1800000 ? "active" : "")} onClick={() => setTimeCutOff(1800000)}>30min</div>
			<div className={'timeframe-selector ' + (timeCutOff === 3600000 ? "active" : "")} onClick={() => setTimeCutOff(3600000)}>1hr</div>
		</div>
	</div>
	  <div className="event-list" style={{}}>
		<div style={{padding: 0}} >
			<div style={{display: 'flex', justifyContent: 'space-between', fontWeight: "bold", borderBottom: "1px solid #000"}}>
				<div style={{textAlign: 'left'}}>Title</div>
				<div style={{marginLeft: "auto"}}>Price</div>
				<div style={{marginLeft: "auto", marginRight: 5, marginLeft: 15, minWidth: 110}}>Net Volumne</div>
				<div style={{minWidth: 100, textAlign: "right"}}>Total Volumne</div>
				<div style={{textAlign: "center", width: 65}}>Link</div>
			</div>
		</div>
		<TradeList sortedTradeActivity={sortedTradeActivity}/>

	  </div>
    </div>
  );
};

export default Activity;
