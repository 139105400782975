import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const WalletPage = () => {
    const [positions, setPositions] = useState([]);
    const [data, setData] = useState([]);
    const [posHistory, setPosHistory] = useState(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const walletID = queryParams.get('walletID');

    const formatNum = (num) => `${parseFloat(num).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
	const getPercentage = (arr) => {
		let percentageChange = 0
		try {
			let len = arr.length
			if (len > 1) {
				let firstEle = arr[0]
				let lastEle = arr[len - 1]
				percentageChange = ((lastEle - firstEle) / firstEle) * 100;
			}
		} catch (error) {
			
		}
		
		return percentageChange
	}
    useEffect(() => {
        const fetchData = async () => {
            try {
                const dataResponse = await axios.get('https://polymarket.niklasoe.uber.space/data');
                setData(dataResponse.data);

                if (walletID) {
                    const positionsResponse = await axios.get(`https://polymarket.com/api/profile/positions?user=${walletID}`);
                    setPositions(positionsResponse.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const parseData = async () => {
            await fetchData();

            const positionsState = await new Promise(resolve => setPositions(prev => {
                resolve(prev);
                return prev;
            }));

            const dataState = await new Promise(resolve => setData(prev => {
                resolve(prev);
                return prev;
            }));

            const positionsSlug = positionsState.map(pos => pos.slug);
            const positionsHistory = {};

            dataState.forEach(trade => {
				const fiveMinutesAgo = Date.now() - 5 * 60 * 1000; // 5 minutes in milliseconds
				const fifteenMinutesAgo = Date.now() - 15 * 60 * 1000;
				const thirtyMinutesAgo = Date.now() - 30 * 60 * 1000; // 30 minutes in milliseconds
				const oneHourAgo = Date.now() - 60 * 60 * 1000; // 1 hour in milliseconds
				const threeHoursAgo = Date.now() - 3 * 60 * 60 * 1000; // 3 hours in milliseconds

                const tradeTimestamp = new Date(trade.timestamp).getTime() * 1000;


                if (positionsSlug.includes(trade.slug)) {
                    if (!positionsHistory[trade.slug]) {
                        positionsHistory[trade.slug] = {
                            priceHistory: [],
                            "yes-prices": [],
                            "no-prices": [],
							"5min": {
								"yes-prices": [],
                            	"no-prices": [],
							},
							"15min": {
								"yes-prices": [],
                            	"no-prices": [],
							},
							"30min": {
								"yes-prices": [],
                            	"no-prices": [],
							},
							"60min": {
								"yes-prices": [],
                            	"no-prices": [],
							},
							"180min": {
								"yes-prices": [],
                            	"no-prices": [],
							}
                        };
                    }
					if (tradeTimestamp > fiveMinutesAgo) { 
						if (trade.outcome === "Yes") {
							positionsHistory[trade.slug]["5min"]["yes-prices"].push(trade.price);
						} else if (trade.outcome === "No") {
							positionsHistory[trade.slug]["5min"]["no-prices"].push(trade.price);
						}
					}
					if (tradeTimestamp > fifteenMinutesAgo) { 
						if (trade.outcome === "Yes") {
							positionsHistory[trade.slug]["15min"]["yes-prices"].push(trade.price);
						} else if (trade.outcome === "No") {
							positionsHistory[trade.slug]["15min"]["no-prices"].push(trade.price);
						}
					}
					if (tradeTimestamp > thirtyMinutesAgo) { 
						if (trade.outcome === "Yes") {
							positionsHistory[trade.slug]["30min"]["yes-prices"].push(trade.price);
						} else if (trade.outcome === "No") {
							positionsHistory[trade.slug]["30min"]["no-prices"].push(trade.price);
						}
					}
					if (tradeTimestamp > oneHourAgo) { 
						if (trade.outcome === "Yes") {
							positionsHistory[trade.slug]["60min"]["yes-prices"].push(trade.price);
						} else if (trade.outcome === "No") {
							positionsHistory[trade.slug]["60min"]["no-prices"].push(trade.price);
						}
					}
					if (tradeTimestamp > threeHoursAgo) { 
						if (trade.outcome === "Yes") {
							positionsHistory[trade.slug]["180min"]["yes-prices"].push(trade.price);
						} else if (trade.outcome === "No") {
							positionsHistory[trade.slug]["180min"]["no-prices"].push(trade.price);
						}
					}
					
                    if (trade.outcome === "Yes") {
                        positionsHistory[trade.slug]["yes-prices"].push(trade.price);
                    } else if (trade.outcome === "No") {
                        positionsHistory[trade.slug]["no-prices"].push(trade.price);
                    }
                }
            });

            setPosHistory(positionsHistory);
        };

        parseData();
    }, [walletID]);

    return (
        <div>
            <h1>Wallet Page</h1>
            <p>{walletID ? `Wallet ID: ${walletID}` : "No wallet ID provided."}</p>
            <div className='event-list'>
				<div style={{ display: "flex", justifyContent: "space-between", lineHeight: "28px" }} className='list-item'>
					<div style={{ display: "flex" }}>
						<div style={{ padding: "0px 5px", width: 25, marginRight: 5 }}>
							outcome
						</div>
						<div>Title</div>
					</div>

						<div style ={{display: "flex", marginLeft: "auto"}}>
							<div style={{marginLeft: "auto", marginRight: 15, width: 70}}>5 hours</div>
							<div style={{marginLeft: "auto", marginRight: 15, width: 70}}>3 hours</div>
							<div style={{marginLeft: "auto", marginRight: 15, width: 70}}>1 hour</div>
							<div style={{marginLeft: "auto", marginRight: 15, width: 70}}>30min</div>
							<div style={{marginLeft: "auto", marginRight: 15, width: 70}}>15min</div>
							<div style={{marginLeft: "auto", marginRight: 15, width: 70}}>5min</div>
						</div>
					<div style={{width: 100, textAlign: "right", marginRight: 5}}>Current value</div>
				</div>
                {positions.map(position => {
					let show = false 
					if (posHistory) {
						if (posHistory[position.slug]) {
							show = true
						}
					}
					return (
                    <div key={position.slug} style={{ display: "flex", justifyContent: "space-between", lineHeight: "28px" }} className='list-item'>
                        <div style={{ display: "flex" }}>
                            <div style={{ background: position.outcome === "Yes" ? "#4BB543" : "#FC100D", padding: "0px 5px", width: 25, marginRight: 5 }}>
                                {position.outcome}
                            </div>
                            <div>{position.title}</div>
                        </div>

                        {show && (
							<div style ={{display: "flex", marginLeft: "auto"}}>
								<div style={{marginLeft: "auto", marginRight: 15, width: 70}}>{getPercentage(posHistory[position.slug][position.outcome.toLowerCase() + "-prices"]).toFixed(2) + "%"}</div>
								<div style={{marginLeft: "auto", marginRight: 15, width: 70}}>{getPercentage(posHistory[position.slug]["180min"][position.outcome.toLowerCase() + "-prices"]).toFixed(2) + "%"}</div>
								<div style={{marginLeft: "auto", marginRight: 15, width: 70}}>{getPercentage(posHistory[position.slug]["60min"][position.outcome.toLowerCase() + "-prices"]).toFixed(2) + "%"}</div>
								<div style={{marginLeft: "auto", marginRight: 15, width: 70}}>{getPercentage(posHistory[position.slug]["30min"][position.outcome.toLowerCase() + "-prices"]).toFixed(2) + "%"}</div>
								<div style={{marginLeft: "auto", marginRight: 15, width: 70}}>{getPercentage(posHistory[position.slug]["15min"][position.outcome.toLowerCase() + "-prices"]).toFixed(2) + "%"}</div>
								<div style={{marginLeft: "auto", marginRight: 15, width: 70}}>{getPercentage(posHistory[position.slug]["5min"][position.outcome.toLowerCase() + "-prices"]).toFixed(2) + "%"}</div>
							</div>
                        )}
                        <div style={{width: 100, textAlign: "right", marginRight: 5}}>{formatNum(position.currentValue)}$</div>
                    </div>
                )})}
            </div>
        </div>
    );
};

export default WalletPage;
